import { Button, InputBase, Modal, PasswordInput, SegmentedControl, TextInput, createTheme, Notification, FileInput, Title, Tabs, TabsPanel, TabsTab, Stack } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";

const defaultProps = {
  radius: 'xl',
  size: 'md',
  fz: 'h5'
}

export const theme = createTheme({
  /* Put your mantine theme override here */
  fontFamily: 'Space Grotesk',
  defaultRadius: 2,
  primaryColor: 'gray',
  headings: {
    fontFamily: 'Space Grotesk',
  },
  components: {
    Title: Title.extend({
      defaultProps: {
        ff: "Space Grotesk"
      }
    }),
    InputBase: InputBase.extend({
      defaultProps
    }),
    TextInput: TextInput.extend({
      defaultProps
    }),
    PasswordInput: PasswordInput.extend({
      defaultProps
    }),
    Button: Button.extend({
      defaultProps
    }),
    TimeInput: Button.extend({
      defaultProps
    }),
    DateTimePicker: DateTimePicker.extend({
      defaultProps: {
        size: 'md',
        radius: 'xl',
        fz: 'h5'
      }
    }),
    Modal: Modal.extend({
      defaultProps: {
        radius: 'xl',
        overlayProps: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      },
    }),
    SegmentedControl: SegmentedControl.extend({
      defaultProps
    }),
    Notification: Notification.extend({
      defaultProps: {
        radius: 'xl',
        fz: 'md',
        styles: {
          description: { color: 'white' }
        }
      }
    }),
    FileInput: FileInput.extend({
      defaultProps
    }),
    Tabs: Tabs.extend({
      defaultProps: {
        radius: 'lg',
      }
    }),
    TabsTab: Tabs.Tab.extend({
      defaultProps: {
        ff: "Roboto Slab"
      }
    }),
  }
});
