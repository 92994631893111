import { ActionIcon, Group } from "@mantine/core"
import React from "react";
import { Profile } from "./Profile";
import { IconHelpCircle, IconHelpSmall } from "@tabler/icons-react";
import { navigate } from "gatsby";

export function Right() {
    return <Group justify='end' w={{ base: 'auto', md: '30%' }}>
        {/* <ThemeSwitcher /> */}
        <Profile />
        <ActionIcon
            onClick={() => navigate('/help')}
            variant="outline"
            size={'md'}
            radius={'xl'}
        >
            <IconHelpSmall />
        </ActionIcon>
    </Group>
}