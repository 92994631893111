import { Group, Image } from "@mantine/core"
import { Link } from "gatsby";
import React from "react";

export function Logo() {
    return <Group>
        <Link to='/'>
            <Image src={'/img/logo.svg'} w={{ base: 180, md: 'auto' }} height={'auto'} />
        </Link>
    </Group>
}