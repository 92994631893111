import { ActionIcon, AppShell, BackgroundImage, Box, Container, Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { DoubleNavbar } from './Navbar/Navbar';
import { Role, useCheckRoles } from '../context/AuthProvider';
import { Footer } from './Footer/Footer';
import { Logo } from './Header/Logo';
import { Left } from './Header/Left';
import { Right } from './Header/Right';
import { IconList, IconX } from '@tabler/icons-react';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { Partners } from './Header/Partners';

export function Layout({ children, location }: any) {
    const pathname = location?.pathname
    const [opened, { toggle, open, close }] = useDisclosure();

    // useGoogleOneTapLogin({
    //     onSuccess: credentialResponse => {
    //         console.log(credentialResponse);
    //     },
    //     onError: () => {
    //         console.log('Login Failed');
    //     },
    // });

    const right = useCheckRoles([Role.Admin]);
    const navbarWith = 250
    const headerHeight = 60
    // const footerHeight = 60

    const authenPages = ['/signin/', '/signup/'].includes(pathname)

    if (authenPages) return <AppShell>
        <AppShell.Main>
            {children}
        </AppShell.Main>
    </AppShell>

    return (
        <AppShell
            header={{
                height: headerHeight,
                //collapsed: // !pinned,
                offset: true,
            }}
        // navbar={{
        //     width: navbarWith,
        //     breakpoint: 'sm',
        //     collapsed: { mobile: !opened, desktop: !right },
        // }}
        // footer={{
        //     height: footerHeight,
        //     collapsed: !inViewport // || !pinned
        // }}
        >
            <BackgroundImage src='/img/Skybox.png'>
                <AppShell.Header bg={'rgba(0, 0, 0, 0.5)'} withBorder={false} /* pl={{ xs: 'auto', md: inViewportSidebar ? navbarWith : 'auto' }}*/ >
                    <Group h="100%" px="md">
                        <Group justify="space-between" align='center' w={'100%'}>
                            <Left />
                            <Logo />
                            {/* <Menu /> */}
                            {/* <Search /> */}
                            {/* <LangSwitcher /> */}
                            <Right />
                            {/* {right && <Box hiddenFrom="sm">
                                <ActionIcon
                                    onClick={toggle}
                                    variant="light"
                                    p={5}
                                    size={'lg'}>
                                    {opened ? <IconX /> : <IconList />}
                                </ActionIcon>
                            </Box>} */}
                        </Group>
                    </Group>
                </AppShell.Header>
                {/* <AppShell.Navbar p="sm">
                    <DoubleNavbar location={location} onChange={close} />
                </AppShell.Navbar> */}
                <AppShell.Main>
                    <Container size={'xl'} py={'md'}>
                        <Stack>
                            <Box hiddenFrom="md">
                                <Partners />
                            </Box>
                            {children}
                        </Stack>
                    </Container>
                    {/* <Box mt={'auto'}>
                        <Footer />
                    </Box> */}
                </AppShell.Main>
                {/* <AppShell.Footer bg={'dark.6'} ref={ref} hiddenFrom='sm'>
                    <MobileNavigator location={location} />
                </AppShell.Footer> */}
            </BackgroundImage>
        </AppShell>
    );
}