import * as React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { navigate } from "gatsby";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { axiosInstance } from "../axiosConfigs";
import { getProfile } from "../modules/profile/hooks";
import { GoogleOAuthProvider } from '@react-oauth/google';
import PubSub from 'pubsub-js'
import { NotificationData, notifications } from "@mantine/notifications";
import { useTranslation } from "../components";

export enum Role {
    Default = 0,
    Admin = "Admin",
}

function deleteCookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export class SigninProps {
    username: string = ''
    password: string = ''
    remember: boolean = false
    redirectUrl?: string = '/'
}

export class AuthContextProps {
    userInfo: any | undefined
    signin: ((params: SigninProps) => void) | undefined
    signout: () => void = () => { }
    fetchProfile: () => void = () => { };
}

const AuthContext = createContext<AuthContextProps>(new AuthContextProps());

export function AuthProvider({ children }: any) {
    const { t } = useTranslation()
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [userInfo, setUserInfo] = useState<any>();

    const signin = (params: SigninProps) => {
        const { username, password, remember, redirectUrl } = params
        const data = new URLSearchParams();
        data.append("username", username);
        data.append("password", password);
        data.append("grant_type", "password");
        data.append("client_secret", "9LcoA0g5DVwTbHYTpXttj5WQZfO7kozS");
        data.append("client_id", "tmv-client");

        axiosInstance.post(`/token`, data, {
            baseURL: process.env.GATSBY_AUTH_URL,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((x) => {
            //console.log(x)
            if (!x) return;
            setCookie('user', x.data)
            if (redirectUrl) navigate(redirectUrl)
        })
    }
    const signout = () => {
        // console.log('deleteCookie')
        deleteCookie('user')
        navigate('/signin')
    };


    const fetchProfile = () => {
        getProfile().then(data => {
            const profile = data?.data?.profile
            if (!profile) return;
            // console.log(profile)
            setUserInfo({ ...userInfo, ...profile })
        })
    }

    useEffect(() => {
        // console.log('cookies', cookies)
        if (cookies.user && cookies.user.access_token) {
            const decoded = jwtDecode(cookies.user.access_token);
            const diff = dayjs().diff(dayjs.unix(decoded.exp as number))
            if (diff > 0) { // expired
                deleteCookie('user')
                navigate('/signin')
            } else {
                const token = cookies.user.access_token;
                const decoded = jwtDecode(token);

                // setUserInfo(decoded)

                getProfile().then(data => {
                    const profile = data?.data?.profile
                    if (!profile) return;
                    // console.log(profile)
                    setUserInfo({ ...decoded, ...profile })
                })
            }
        } else {
            setUserInfo(undefined)
            //navigate('/signin')
        }
    }, [cookies])

    useEffect(() => {
        var token = PubSub.subscribe('NOTIFICATION', (msg: string, data: NotificationData) => {
            notifications.show({ ...data, bg: 'red', title: t(data.title as string), message: t(data.message as string), autoClose: 1000 })
        });

        return () => { PubSub.unsubscribe(token) }
    }, [])

    return (
        <>
            <AuthContext.Provider value={{ userInfo, signin, signout, fetchProfile }}>
                <GoogleOAuthProvider clientId={process.env.GATSBY_GOOGLE_AUTH_CLIENT_ID as string}>
                    {children}
                </GoogleOAuthProvider>
            </AuthContext.Provider>
        </>
    );
};

export default AuthContext;

export function useAuthContext(): AuthContextProps {
    const context = useContext(AuthContext);
    return context;
}

export function useInRole(role: Role): boolean {
    const { userInfo } = useAuthContext();
    // console.log('userInfo', userInfo)
    const right = React.useMemo(() => {
        if (!userInfo) return false;
        return userInfo?.role === role;
    }, [userInfo])

    //console.log(right)
    return right;
}

export function useCheckRoles(roles: Array<Role>): boolean {
    const { userInfo } = useAuthContext();
    // console.log('userInfo', userInfo)
    const right = React.useMemo(() => {
        if (!userInfo) return false;

        if (!roles) return false;

        let checked = false;

        // @ts-ignore
        userInfo?.roles?.forEach((role: Role) => {
            if (roles.includes(role) === true) checked = true
        })

        return checked;

    }, [userInfo])

    //console.log(right)
    return right;
}

export function useAuthorization(roles: Array<Role> = []) {
    const right = useCheckRoles(roles)

    if (!right) navigate("/signin")

    return right;
}